<template>
    <div class="second-container-list">
        <div class="container-search">
            <input type="search" class="form-control" placeholder="Buscar cliente" />
            <div>
                <button type="button" class="btn" data-bs-toggle="modal" data-bs-target="#exampleModal"
                    title="Agregar Crédito">
                    <box-icon name='add-to-queue' ></box-icon>
                </button>
            </div>
        </div>
        <table class="table table-responsive table-hover caption-top">
            <thead>
                <tr align="center">
                    <th scope="col">Nombre de la Empresa</th>
                    <th scope="col">Fecha de Registro</th>
                    <th scope="col">Contacto</th>
                    <th scope="col">Teléfono</th>
                    <th scope="col">Ver Vacantes</th>
                    <!-- <th scope="col">Estatus</th> -->
                    <!-- <th scope="col">Opciones</th> -->
                </tr>
            </thead>
            <tbody v-for="client in clients" v-bind:key="client">
                <tr align="center">
                    <td style="width:18%">{{ client.informationContributors.nameCompany }}</td>
                    <td>{{ moment.utc(client.createdAt).format("DD/MM/YYYY") }} </td>
                    <td style="text-transform: capitalize">{{ client.informationContributors.responsableCompany }}</td>
                    <td>{{ client.informationContributors.phoneCompany }}</td>
                    <td>
                        <div class="select-list-evaluados">
                            <router-link class="btn-asignar-evaluacion" to="/administration/clients/list/candidates"
                                title="Ver Candidatos">
                                <box-icon name='list-ul' type="solid" ></box-icon>
                            </router-link>
                        </div>
                    </td>
                    <!-- <td>
                        <span class="badge text-bg-success">{{ client.status }}</span>
                    </td> -->
                    <!-- <td>
                        <div class="select-status-account">
                            <span>
                                <div class="form-check form-switch">
                                    <input class="form-check-input" type="checkbox" role="switch"
                                        id="flexSwitchCheckDefault">
                                </div>
                            </span>
                        </div>
                    </td> -->
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
import * as ClientService from "../../services/client"
import moment from 'moment-timezone';
moment.tz.setDefault("America/Mexico_City");

export default {
    data: () => ({
        listCompany: [],
        newClient: "",
        clients: [],
        moment: moment,

    }),
    mounted() {
        const user = JSON.parse(localStorage.getItem("user") || null);
        const { company: { _id: _id_company } } = user
        this.contributorsList(_id_company);
    },
    methods: {
        async contributorsList(filter) {
            let resp = await ClientService.contributorsList({ id_client: filter })
            this.clients = resp.data.data
        },
    },
};

</script>
<style lang="scss">
@import "../../styles/registerClient.scss";
</style>